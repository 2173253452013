import Axios from 'axios';
// import _ from 'lodash';
// import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';

export const ANALYTICS_CALCULATE_DATA_INIT = createAction(
  'ANALYTICS_CALCULATE_DATA_INIT'
);
export const ANALYTICS_CALCULATE_DATA_SUCCESS = createAction(
  'ANALYTICS_CALCULATE_DATA_SUCCESS'
);
export const ANALYTICS_CALCULATE_DATA_FAIL = createAction(
  'ANALYTICS_CALCULATE_DATA_FAIL'
);

export const calculateData = ({ start, end, location }) => {
  return async (dispatch, getState) => {
    dispatch(ANALYTICS_CALCULATE_DATA_INIT());

    console.log('start calculate data');

    let analytic;
    const data = {
      start,
      end,
    };
    if (location) {
      data.location = location;
    }
    try {
      analytic = await Axios.post(
        `${url}/facial/calculate`,
        data,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_CALCULATE_DATA_FAIL({ error }));
    }

    // const duration = 30;
    // var startTime = moment().startOf('day').add(30, 'minutes');
    // var endTime = moment().endOf('day');
    // let groupedDuration = [];

    // for (let d = startTime; d.isSameOrBefore(endTime); d.add(30, 'minutes')) {
    //   const startLoop = moment(new Date(d)).subtract(30, 'minutes');

    //   var dates = data.filter((all) =>
    //     isTimeBetween(
    //       startLoop.format('HH:mm a'),
    //       d.format('HH:mm a'),
    //       moment(new Date(all.date)).format('HH:mm a')
    //     )
    //   );
    //   // d = d.add(duration, "minutes");

    //   groupedDuration.push({
    //     x: d.format('HH:mm'),
    //     // y: groupedY,
    //     y: dates.reduce((acc, val, i, arr) => {
    //       if (i === 0) {
    //         return acc + val.count;
    //       } else {
    //         const next = arr[i + 1];
    //         if (next) {
    //           return acc + next.count;
    //         } else {
    //           return acc;
    //         }
    //       }
    //     }, 0),
    //   });
    // }
    // groupedDuration = _.orderBy(groupedDuration, 'y', 'desc');

    console.log(analytic, 'calculate data');
    return dispatch(
      ANALYTICS_CALCULATE_DATA_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};

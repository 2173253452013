import { Table, Tooltip, Tag } from 'antd';
import ModalSiteForm from 'components/ModalSiteForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import DataCard from '../../design-components/DataCard';
import { log } from 'utils';
import {
  createSite,
  deleteSite,
  fetchSites,
  modifySite,
} from 'state/actions/sites';
// import Table from 'components/Table';
import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Sites.scss';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import _ from 'underscore';
import PageHeader from 'design-components/PageHeader';
import { EditGrey, TrashGrey } from 'assets/icon';

const Sites = () => {
  const {
    // isAdmin,

    loading,
    deleted,
    updated,
    sitesList,
    userData,
    role,
  } = useSelector(
    (state) => ({
      // isAdmin: state.auth.userData.isAdmin,

      loading: state.sites.loading,
      deleted: state.sites.deleted,
      updated: state.sites.updated,
      sitesList: state.sites.list,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const defaultSite = {
    name: '',
    description: '',
    organization: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };

  const [deleteModal, setDeleteModal] = useState({
    siteId: '',
    siteName: '',
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [site, setSite] = useState(defaultSite);
  const [error, setError] = useState(null);
  const [isGridSelected, setIsGridSelected] = useState(false);

  const dispatch = useDispatch();

  const redirect = role === 'superadmin' ? false : <Redirect to={paths.ROOT} />;

  useEffect(() => {
    if (role === 'superadmin') {
      dispatch(fetchSites());
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, role]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchSites());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchSites());
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setBreadcrumb([{ menu: 'Site', link: '/sites' }]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGridMenu = () => {
    setIsGridSelected(true);
  };

  const handleListMenu = () => {
    setIsGridSelected(false);
  };

  const onCreate = (values) => {
    const action = isEditing ? modifySite : createSite;
    log(values, '<<<< values site oncreate');
    dispatch(action(values));
  };

  const onNewSiteHandler = () => {
    log('handle new site');
    setSite(defaultSite);
    setIsEditing(false);
    setVisible(true);
  };

  const editAction = (site) => {
    setSite(site);
    setVisible(true);
    setIsEditing(true);
  };

  const onRemoveButtonClickHandler = (siteId, siteName) => {
    setDeleteModal((prevState) => ({
      siteId,
      siteName,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ siteId: null, isOpen: false });
  };

  const onDeleteSiteHandler = () => {
    dispatch(deleteSite(deleteModal.siteId));
  };

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'SITE NAME',
      dataIndex: 'name',
      render: (text) => (
        <div className="table-name">
          {text.length > 19 ? text.substr(0, 20) + '...' : text}
        </div>
      ),
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'organizationName',
      render: (text) => <div className="table-organization">{text}</div>,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '30%',
      render: (text) => <div className="table-desc">{text}</div>,
    },
    {
      title: 'LAST MODIFIED BY',
      dataIndex: 'modifiedBy',
      render: (text, record) => {
        return <div>{record.modifiedBy}</div>;
      },
    },
    {
      title: 'LAST MODIFIED DATE',
      dataIndex: 'modifiedAt',
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.modifiedAt)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'option',

      render: (text, record) => {
        return (
          <div className="sites__actions">
            <Tooltip title="Edit Site">
              <button
                onClick={() => editAction(record)}
                data-tooltip="Edit"
                className="btn btn--edit"
              >
                <EditGrey /> <span className='btn-text'>Edit</span>
              </button>
            </Tooltip>

            <Tooltip title="Delete Site">
              <button
                type="button"
                onClick={() =>
                  onRemoveButtonClickHandler(record._id, record.name)
                }
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <TrashGrey /> <span className='btn-text'>Delete</span>  
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const data = search
    ? _.sortBy(sitesList, (i) => i.name.toLowerCase()).filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : _.sortBy(sitesList, (i) => i.name.toLowerCase());

  const permDeleteMessage = useFormatMessage('Sites.permDelete');

  return (
    <section className="sites">
      <PageHeader
        title="Sites"
        isGrid={true}
        isGridSelected={isGridSelected}
        gridAction={handleGridMenu}
        listAction={handleListMenu}
        isSearch={true}
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter Site's Name"
        searchError={error}
        isAdd={true}
        addAction={onNewSiteHandler}
        addLabel="Add Site"
      />

      {redirect}
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title={'Delete Site'}
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteSiteHandler}
          description={permDeleteMessage}
          visible={deleteModal.isOpen}
        />
      )}

      {visible && (
        <ModalSiteForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          site={site}
          setSite={setSite}
          isEditing={isEditing}
        />
      )}

      {isGridSelected ? (
        <DataCard
          data={data}
          info="site"
          editAction={editAction}
          removeAction={onRemoveButtonClickHandler}
        ></DataCard>
      ) : (
        <div className="sites__table">
          {loading ? (
            <ClipLoader />
          ) : (
            <Table columns={columns} dataSource={data} rowKey="_id" />
          )}
          {error && 'Show error'}
        </div>
      )}
    </section>
  );
};

export default Sites;

import { createReducer } from 'redux-act';
// import moment from 'moment';

import {
  MEDIA_FETCH_DATA_INIT,
  MEDIA_FETCH_DATA_SUCCESS,
  MEDIA_FETCH_DATA_FAIL,
  MEDIA_DELETE_MEDIA_INIT,
  MEDIA_DELETE_MEDIA_SUCCESS,
  MEDIA_DELETE_MEDIA_FAIL,
  BULK_MEDIA_DELETE_INIT,
  BULK_MEDIA_DELETE_SUCCESS,
  BULK_MEDIA_DELETE_FAIL,
  MEDIA_CLEAR_DATA,
  MEDIA_CREATE_MEDIA_INIT,
  MEDIA_CREATE_MEDIA_SUCCESS,
  MEDIA_CREATE_MEDIA_FAIL,
  MEDIA_MODIFY_MEDIA_INIT,
  MEDIA_MODIFY_MEDIA_SUCCESS,
  MEDIA_MODIFY_MEDIA_FAIL,
  MEDIA_CLEAN_UP,
  MEDIA_CLEAR_DATA_LOGOUT,
  MEDIA_BULK,
  SET_MEDIA,
  ORDER_MEDIA,
} from 'state/actions/media';

const initialState = {
  list: [],
  media: {
    // this is the old one, Imma change it with a new one
    // name: '',
    // description: '',
    // start: '',
    // end: '',
    // duration: 5,
    // fileUrl: null,
    // siteId: '',
    // modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    // modifiedBy: '',
    // type: 'all',
    // dates: [],
    // color: '#000000',
    // fileType: '',
    days: [],
    name: '',
    description: '',
    categoryList: [],
    siteId: '',
    file: '',
    isRemoved: false,
    duration: 5,
    start: null,
    end: null,
  },
  loading: false,
  error: {
    errors: null,
    messsage: '',
  },
  success: false,
  deleted: false,
  updated: false,
};

export const mediaReducer = createReducer(
  {
    [MEDIA_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIA_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      media: payload.media || initialState.media,
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
    }),
    [MEDIA_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_DELETE_MEDIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_DELETE_MEDIA_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      deleted: true,
      updated: true,
    }),
    [MEDIA_DELETE_MEDIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [BULK_MEDIA_DELETE_INIT]: (state) => ({
      ...state,
      deleted: false,
      loading: true,
    }),
    [BULK_MEDIA_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((md) => {
        // eslint-disable-line
        if (payload.data.every((fd) => fd !== md._id)) {
          return md;
        }
        return false;
      }),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      deleted: true,
      updated: true,
    }),
    [BULK_MEDIA_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      deleted: false,
      error: payload.error,
    }),
    [MEDIA_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [MEDIA_CREATE_MEDIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_CREATE_MEDIA_SUCCESS]: (state, payload) => ({
      ...state,
      list: [...state.list, ...payload.media],
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: true,
      updated: true,
    }),
    [MEDIA_CREATE_MEDIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_MODIFY_MEDIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_MODIFY_MEDIA_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.media.name,
                description: payload.media.description,
                start: payload.media.start,
                end: payload.media.end,
                duration: payload.media.duration,
                fileUrl: payload.media.fileUrl,
                siteId: payload.media.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.media.modifiedBy,
                type: payload.media.type,
                fileType: payload.media.fileType,
                color: payload.media.color,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: true,
      updated: true,
    }),
    [MEDIA_MODIFY_MEDIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: false,
      deleted: false,
      updated: false,
    }),
    [MEDIA_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [MEDIA_BULK]: (state, payload) => ({
      ...state,
      list: payload.medias,
    }),
    // added by me
    [SET_MEDIA]: (state, payload) => ({
      ...state,
      media: payload.media,
    }),
    [ORDER_MEDIA]: (state, payload) => ({
      ...state,
      list: payload.media,
    }),
  },
  initialState
);

import { createReducer } from 'redux-act';

import {
  ANALYTICS_CALCULATE_DATA_INIT,
  ANALYTICS_CALCULATE_DATA_SUCCESS,
  ANALYTICS_CALCULATE_DATA_FAIL,
} from 'state/actions/rawAnalytic';

const initialState = {
  rawData: [],

  loadingCalculate: false,
  error: null,
};

export const rawAnalyticReducer = createReducer(
  {
    [ANALYTICS_CALCULATE_DATA_INIT]: (state, payload) => ({
      ...initialState,
      loadingCalculate: true,
    }),
    [ANALYTICS_CALCULATE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      rawData: payload.data || [],
      loadingCalculate: false,
      error: null,
    }),
    [ANALYTICS_CALCULATE_DATA_FAIL]: (state, payload) => ({
      ...state,
      rawData: payload.data || [],
      loadingCalculate: false,
      error: payload.error,
    }),
  },
  initialState
);

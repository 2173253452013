import { Form, Input, Modal, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { screensCleanUp } from 'state/actions/screens';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';

export default function ModalTemplateForm({
  template,
  setTemplate,
  visible,
  onCancel,
  onCreate,
  history,
}) {
  const dispatch = useDispatch();
  const { siteId, siteName } = useParams();

  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    return () => dispatch(screensCleanUp());
  }, [dispatch]);
  useEffect(() => {
    console.log(template);
  }, [template]);

  const onChangeHandler = useChangeHandler(setTemplate);

  const onSave = (values) => {
    console.log(values, 'values');
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else if (!template.width || !template.height || !values.orientation) {
      console.log('here');
      setErrors({
        ...errors,
        size: !values.width && 'This field is required',
        orientation: !values.orientation && 'This field is required',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        template,
      });
      form.resetFields();
      history.push(`/${siteId}/information/${siteName}/template/new`);
    }
  };

  const handleChangeSize = (value) => {
    const sizes = value.split('x');
    setTemplate({
      ...template,
      width: parseInt(sizes[0]),
      height: parseInt(sizes[1]),
    });
  };
  const handleChangeOrientation = (value) => {
    setTemplate({
      ...template,
      orientation: value,
    });
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={'Add New Template'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={template}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Canvas Size"
          name="size"
          validateStatus={errors.size ? 'error' : 'success'}
          help={errors.size ? errors.size : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeSize}
            options={[
              { value: '1920x1080', label: 'Full HD(1920 x 1080)' },
              { value: '3840x2160', label: 'Ultra HD(3840 x 2160)' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Orientation"
          name="orientation"
          validateStatus={errors.size ? 'error' : 'success'}
          help={errors.size ? errors.size : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeOrientation}
            options={[
              { value: 'portrait', label: 'Portrait' },
              { value: 'landscape', label: 'Landscape' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalTemplateForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

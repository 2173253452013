/* eslint-disable jsx-a11y/label-has-associated-control */
import { fabric } from 'fabric';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TemplateForm.css';
import { CheckBullet, EditIcon, GridIcon, PlusIcon } from 'assets/icon/dynamic';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { ReactComponent as BackwardIcon } from '../../assets/icon/backward-5-seconds.svg';
// import { ReactComponent as ForwardIcon } from '../../assets/icon/forward-5-seconds.svg';
import { ReactComponent as CloseIcon } from '../../assets/icon/close-circle.svg';
import ModalTemplateElementForm from 'components/ModalTemplateElementForm';
import { fetchMediaCategories } from 'state/actions/mediaCategories';
import { Dropdown, Form, Input, Menu, Select, Slider, Tooltip } from 'antd';
import { useChangeHandler } from 'hooks';
import { regexDescription, regexInput } from 'utils/regex';

const TemplateForm = ({
  history,
  template,
  action,
  categoriesList,
  success,
}) => {
  const { siteId, siteName } = useParams();
  const canvas = useRef(null);
  const fabricRef = useRef(null);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });
  const [templateData, setTemplateData] = useState(template);
  const [addElement, setAddElement] = useState(false);
  const [element, setElement] = useState({});
  const [activeObject, setActiveObject] = useState({});
  const [elementList, setElementList] = useState([]);
  const [templateProperties, setTemplateProperties] = useState(false);
  const [mediaCat, setMediaCat] = useState(false);
  const [zoom, setZoom] = useState(100);

  const onChangeHandler = useChangeHandler(setTemplateData);

  const dispatch = useDispatch();

  const addRectangle = () => {
    const rect = new fabric.Rect({
      // top: 50,
      // left: 50,
      width: 100,
      height: 100,
      fill: 'white',
      // borderColor: 'black',
      stroke: 'grey',
      strokeWidth: 1,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
    });

    const cat = new fabric.Textbox(element.name, {
      fontSize: 10,
      fontWeight: 400,
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      splitByGrapheme: true,
      isWrapping: true,
      height: 100,
      width: 100,
    });

    const group = new fabric.Group([rect, cat], {
      left: 150,
      top: 100,
      stroke: 'grey',
      strokeWidth: 1,
      strokeUniform: true,
    });

    const id = Date.now();
    group.set('categoryId', element.categoryId);
    group.set('name', element.name);
    group.set('id', id);
    // group.categoryId = element.cateogryId;

    group.on('scaling', function (obj) {
      var text = obj.transform.target._objects[1];
      var rect = obj.transform.target._objects[0];
      var both = obj.transform.target;

      rect.width = both.width;
      rect.height = both.height;
      text.scaleX = 1;
      text.scaleY = 1;
      text.width = both.width;
      // text.fontSize = 16;
      text._wrapText(1, both.width);
    });
    setElementList([...elementList, { ...element, id }]);
    fabricRef.current.add(group);

    fabricRef.current.setActiveObject(group);
    // addCategory(element.categoryId, element.name);
    setElement({});
  };

  // useEffect(() => {
  //   // console.log(template, 'template in fomr');
  //   // setTemplateData(template);

  // }, [templateData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTemplateData(template);
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvas.current, {
        height:
          template.orientation === 'landscape'
            ? template.height * (700 / template.width)
            : template.width * (700 / template.width),
        width:
          template.orientation === 'landscape'
            ? template.width * (700 / template.width)
            : template.height * (700 / template.width),
        backgroundColor: 'white',

        selection: false,
        renderOnAddRemove: true,
      });
    };

    const disposeFabric = () => {
      fabricRef.current.dispose();
    };
    dispatch(fetchMediaCategories({ siteId }));

    initFabric();
    if (template._id && template.canvas) {
      console.log(template.canvas, 'template canvas');
      setElementList(template.canvas.objects);
      fabricRef.current.loadFromJSON(template.canvas);
    }
    // fabricRef.current.object;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'white';
    fabric.Object.prototype.cornerStyle = 'square';
    fabric.Object.prototype.cornerStrokeColor = '#2C82F6';
    fabric.Object.prototype.cornerSize = 5;

    fabricRef.current.on('selection:updated', (obj) => {
      console.log(obj, 'object updated');
      setActiveObject(obj.selected[0]);
    });
    fabricRef.current.on('selection:created', (obj) => {
      console.log(obj, 'object updated');
      setActiveObject(obj.selected[0]);
    });
    fabricRef.current.on('selection:cleared', (obj) => {
      console.log(obj, 'object updated');
      setActiveObject({});
    });
    // fabricRef.current.on('object:modified', (obj) =>
    //   setTemplateData({
    //     ...templateData,
    //     canvas: fabricRef.current.toJSON(['name', 'id', 'categoryId']),
    //   })
    // );

    return () => {
      disposeFabric();
    };
  }, [template]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    fabricRef.current.setWidth(
      ((templateData.orientation === 'landscape'
        ? templateData.width * (700 / templateData.width)
        : templateData.height * (700 / templateData.width)) *
        zoom) /
        100
    );
    fabricRef.current.setHeight(
      ((templateData.orientation === 'landscape'
        ? templateData.height * (700 / templateData.width)
        : templateData.width * (700 / templateData.width)) *
        zoom) /
        100
    );
    fabricRef.current.setZoom(zoom / 100);
  }, [zoom, templateData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setMediaCat(true);
  }, [activeObject]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mediaCat && templateProperties) {
      setMediaCat(false);
    }
    if (!mediaCat && !templateProperties && activeObject.name) {
      setMediaCat(true);
    }
  }, [templateProperties]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mediaCat && templateProperties) {
      setTemplateProperties(false);
    }
  }, [mediaCat]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}/templates`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSize = (value) => {
    const sizes = value.split('x');
    setTemplateData({
      ...templateData,
      width: parseInt(sizes[0]),
      height: parseInt(sizes[1]),
    });
  };
  const handleChangeOrientation = (value) => {
    setTemplateData({
      ...templateData,
      orientation: value,
    });
  };

  console.log(
    fabricRef.current && fabricRef.current.getActiveObject(),
    'active object'
  );

  const editObject = () => {
    const editedObj = fabricRef.current
      .getObjects()
      .find((obj) => obj.id === element.id);
    editedObj.name = element.name;
    editedObj.categoryId = element.categoryId;
    const index = elementList.findIndex((el) => el.id === element.id);
    elementList[index] = element;
    setElement({});
  };

  const submit = () => {
    if (
      (!regexInput(templateData.name) && templateData.name) ||
      (!regexDescription(templateData.description) && templateData.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(templateData.name) &&
          templateData.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(templateData.description) &&
          templateData.description &&
          'This field cannot contain any special characters',
      });
    } else if (
      !templateData.width ||
      !templateData.height ||
      !templateData.orientation
    ) {
      console.log('here');
      setErrors({
        ...errors,
        size: !templateData.width && 'This field is required',
        orientation: !templateData.orientation && 'This field is required',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      dispatch(
        action({
          template: {
            ...templateData,
            canvas: fabricRef.current.toJSON(['name', 'categoryId', 'id']),
            id: template._id,
          },
        })
      );
    }
  };

  return (
    <div className="template-form">
      <ModalTemplateElementForm
        visible={addElement}
        onCancel={() => {
          setElement();
          setAddElement(false);
        }}
        onCreate={(values) => {
          if (element.id) {
            editObject();
          } else {
            addRectangle();
          }
          setAddElement(false);
        }}
        element={element}
        setElement={setElement}
        history={history}
      />
      <div className="element-panel">
        <div className="element-action">
          <div className="action-wrapper">
            {/* <BackwardIcon />
            <ForwardIcon /> */}
          </div>
          <div className="button-action">
            <button
              className="button-link primary flex-center"
              type="button"
              onClick={() => setAddElement(true)}
            >
              <PlusIcon color="#c5c5c5" size={18} />
              Element
            </button>
          </div>
        </div>
        <div className="element-list">
          {fabricRef.current &&
            elementList.map((obj, ind) => (
              <div
                key={ind + obj.name}
                onClick={() => {
                  const activeFab = fabricRef.current
                    .getObjects()
                    .find((fab) => fab.name === obj.name);

                  if (activeFab) {
                    fabricRef.current.setActiveObject(activeFab);
                    fabricRef.current.renderAll();
                  }
                }}
                className={`element-item ${
                  obj.name === activeObject.name ? 'active' : ''
                }`}
              >
                <div className="flex-center">
                  <GridIcon color="#2DA5A1" />
                  <div className="ml-2">{obj.name}</div>
                </div>
                <Tooltip title="Options">
                  <Dropdown
                    trigger="click"
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          onClick={() => {
                            setElement(obj);
                            setAddElement(true);
                          }}
                        >
                          <i className="feather-edit mr-2" />
                          Edit
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          key="delete"
                          onClick={() => {
                            // obj.onDeselect = () => false;
                            // fabricRef.current.discardActiveObject();
                            setElementList(
                              elementList.filter((el) => el.name !== obj.name)
                            );
                            fabricRef.current.remove(
                              fabricRef.current
                                .getObjects()
                                .find((fab) => fab.name === obj.name)
                            );
                            // fabricRef.current.renderAll();
                          }}
                        >
                          <i className="feather-trash-2 mr-2" />
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <button>
                      <i className="feather-more-horizontal" />
                    </button>
                  </Dropdown>
                </Tooltip>
              </div>
            ))}
        </div>
        <div className="button-wrapper">
          <button className="button-primary" onClick={() => submit()}>
            Submit
          </button>
        </div>
      </div>
      <div className="editor-wrapper">
        <div className="canvas-action">
          <div className="canvas-slider">
            <Slider
              defaultValue={100}
              value={zoom}
              min={0}
              max={200}
              onChange={(val) => setZoom(val)}
            />
          </div>
          {template.name}{' '}
          <div
            className="flex-center"
            onClick={() => setTemplateProperties(true)}
          >
            <EditIcon color="#64D5D2" /> {` Template Properties`}
          </div>
        </div>
        <div className="canvas-space">
          {/* <form className="form-wrapper"> */}
          <canvas ref={canvas} id="canvas" className="template-canvas" />
          {templateProperties && (
            <div className="template-properties">
              <div className="title-template">
                Template Properties
                <CloseIcon onClick={() => setTemplateProperties(false)} />
              </div>
              <div className="form">
                <Form
                  className="form"
                  layout="vertical"
                  form={form}
                  onChange={onChangeHandler}
                  initialValues={templateData}
                >
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                    validateStatus={errors.name ? 'error' : 'success'}
                    help={errors.name ? errors.name : null}
                  >
                    <Input value={templateData.name} />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    validateStatus={errors.description ? 'error' : 'success'}
                    help={errors.description ? errors.description : null}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Description"
                      value={templateData.description}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Canvas Size"
                    name="size"
                    initialValue={`${templateData.width}x${templateData.height}`}
                    validateStatus={errors.size ? 'error' : 'success'}
                    help={errors.size ? errors.size : null}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleChangeSize}
                      options={[
                        { value: '1920x1080', label: 'Full HD(1920 x 1080)' },
                        { value: '3840x2160', label: 'Ultra HD(3840 x 2160)' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Orientation"
                    name="orientation"
                    initialValue={templateData.orientation}
                    validateStatus={errors.size ? 'error' : 'success'}
                    help={errors.size ? errors.size : null}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleChangeOrientation}
                      options={[
                        { value: 'portrait', label: 'Portrait' },
                        { value: 'landscape', label: 'Landscape' },
                      ]}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
          {activeObject.name && mediaCat && (
            <div className="template-properties">
              <div className="title-template">
                Media Categories
                <CloseIcon onClick={() => setMediaCat(false)} />
              </div>
              <div className="form">
                {categoriesList.map((cat) => (
                  <div className="radio-container">
                    <div className="radio-title">{cat.name}</div>
                    <div
                      className={`radio ${
                        cat._id === activeObject.categoryId ? 'active' : ''
                      }`}
                      onClick={() => {
                        fabricRef.current.getActiveObject().categoryId =
                          cat._id;
                        setActiveObject({
                          ...activeObject,
                          categoryId: cat._id,
                        });
                      }}
                    >
                      {cat._id === activeObject.categoryId && (
                        <CheckBullet color="#64D5D2" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* </form> */}
          {/* <div
            className="button-wrapper"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div>
              <button className="button-danger" onClick={() => remove()}>
                Delete
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TemplateForm;

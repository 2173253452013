import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './Analytic.css';
import {
  EmptyAnalyticIcon,
  FilterIcon,
  ForwardIcon,
  ManIcon,
  PeakTimeIcon,
  PeopleCount,
  PeopleIcon,
  TagUserIcon,
  WomanIcon,
} from 'assets/icon';
import CategoryDropdown from 'components/CategoryDropdown';
import { DatePicker } from 'antd';
import PeopleCounterReport from 'components/PeopleCounterReport';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  cancelAction,
  fetchAnalytics,
  fetchProfileAnalytics,
  loadingAnalytic,
} from 'state/actions/analytic';
import ProfileAnalyzerReport from 'components/ProfileAnalyzerReport';
import DashboardCard from 'components/DashboardCard';
import _ from 'lodash';
import moment from 'moment';
import ModalPeakTime from 'components/ModalPeakTime';

const Analytics = () => {
  const { siteId } = useParams();

  //select report type 'profile analyzer' or 'people counter'
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [tempSelectedCategory, setTempSelectedCategory] = useState(
    selectedCategory
  );

  //select report type 'month' or 'day'
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('month');
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);

  //select report daily time duration
  const [durationOpen, setDurationOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(30);
  const [tempSelectedDuration, setTempSelectedDuration] = useState(
    selectedDuration
  );

  //select location
  const [locationList, setLocationList] = useState([]);
  const [locationOpen, setLocationOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [grouped, setGrouped] = useState();
  const [tempSelectedLocation, setTempSelectedLocation] = useState(
    selectedLocation
  );

  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [peakTime, setPeakTime] = useState(false);
  const [dasboardData, setDashboardData] = useState([]);

  const { analytics, rawAnalytics, loading, locations } = useSelector(
    (state) => ({
      analytics: state.analytic.data,
      rawAnalytics: state.analytic.rawDataInit,
      loading: state.analytic.loading,
      locations: state.analytic.locations,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const reportType = [
    { _id: 'peoplecounter', name: 'Traffic Counter' },
    { _id: 'profileanalyzer', name: 'Profile Analyzer' },
  ];
  const chartType = [
    { _id: 'month', name: 'Pre-defined Range' },
    { _id: 'day', name: '24-hour Range' },
  ];
  const durationType = [
    { _id: 30, name: '30 Minutes' },
    { _id: 60, name: '1 Hour' },
    { _id: 120, name: '2 Hours' },
    { _id: 180, name: '3 Hours' },
  ];

  useEffect(() => {
    let isCanceled = false;
    if (!isCanceled) {
      console.log(selectedType, dateRange, 'use effect');
      if (selectedCategory === 'peoplecounter') {
        console.count('fetch analytics');
        if (
          dateRange.startDate &&
          dateRange.endDate &&
          dateRange.startDate === dateRange.endDate &&
          selectedType === 'month'
        ) {
          console.log(selectedType, 'type');
        } else {
          dispatch(
            fetchAnalytics({
              start: dateRange.startDate,
              end: dateRange.endDate,
              type: selectedType,
              day: date,
            })
          );
        }
      } else if (selectedCategory === 'profileanalyzer') {
        dispatch(
          fetchProfileAnalytics({
            start: dateRange.startDate,
            end: dateRange.endDate,
            type: selectedType,
            day: date,
          })
        );
      }
    } else {
      dispatch(cancelAction());
    }

    return () => (isCanceled = true);
  }, [selectedCategory, selectedType, dateRange, date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (locations) {
      setLocationList(
        locations.map((loc) => {
          return { _id: loc.location, name: loc.location };
        })
      );
    }
  }, [locations]);

  useEffect(() => {
    if (rawAnalytics.length > 0) {
      console.log(rawAnalytics, 'raw analytic');
    }
  }, [rawAnalytics]);
  useEffect(() => {
    if (grouped && !_.isEmpty(grouped)) {
      console.log(grouped, 'grouped');
      const val = Object.values(grouped);
      let total = val.map((arr) => arr.reduce((a, b) => a + b.y, 0));
      total = total.reduce((a, b) => a + b, 0);

      console.log(val, 'val');
      const keysArray = Object.values(grouped)[0].map((v) => v.x);
      const sumArray = [];
      for (let k of keysArray) {
        const totalData = val.map((v) => v.find((dt) => dt.x === k)?.y);
        sumArray.push({ x: k, y: totalData.reduce((a, b) => a + b, 0) });
      }

      var max = _.maxBy(sumArray, 'y');
      var otherMax = sumArray.filter((arr) => arr.y === max.y);

      var dataDashboard = [
        {
          title: 'Human Count (All Screens)',
          data: `${total.toLocaleString('en-US')}`,
          icon: <PeopleCount />,
          color: '#FFBC00',
        },
        {
          title: 'Peak Time',
          data:
            sumArray.length > 1
              ? `${moment(new Date(max.x)).format('HH:mm')} ${
                  otherMax.length > 1 && otherMax.length !== sumArray.length
                    ? `and ${otherMax.length - 1} other time(s)`
                    : ''
                }`
              : '',
          icon: <PeakTimeIcon />,
          color: '#FF7066',
        },
      ];

      setDashboardData(dataDashboard);
    } else {
      var dataDashboardEmpty = [
        {
          title: 'Human Count (All Screens)',
          data: `0`,
          icon: <PeopleCount />,
          color: '#FFBC00',
        },
        {
          title: 'Peak Time',
          data: '-',
          icon: <PeakTimeIcon />,
          color: '#FF7066',
        },
      ];

      setDashboardData(dataDashboardEmpty);
    }
  }, [grouped]);

  useEffect(() => {
    let isCanceled = false;
    if (!isCanceled) {
      console.count('analytics change');
      if (selectedCategory === 'profileanalyzer' && Array.isArray(analytics)) {
        console.log(analytics, 'analytics');
        var female = 0;
        var male = 0;
        var dashboard = analytics.reduce((a, b) => {
          female += b.female;
          male += b.male;
          return a + b.female + b.male;
        }, 0);
        var maxAge = _.maxBy(analytics, (o) => o.male + o.female);
        var otherMaxAge = analytics.filter(
          (arr) => arr.male + arr.female === maxAge.male + maxAge.female
        );

        var data = [
          {
            title: 'Male Percentage',
            data: `${((male / dashboard) * 100).toFixed(2).toString()}%`,
            icon: <ManIcon />,
            color: '#2C82F6',
          },
          {
            title: 'Female Percentage',
            data: `${((female / dashboard) * 100).toFixed(2).toString()}%`,
            icon: <WomanIcon />,
            color: '#FF7066',
          },
          {
            title: 'Highest "Age Range" Count',
            data:
              dashboard > 0
                ? `${maxAge.low.toString()}-${maxAge.high.toString()} Years Old ${
                    otherMaxAge.length > 1
                      ? `and ${otherMaxAge.length - 1} other range(s)`
                      : ''
                  }`
                : '-',
            icon: <PeopleIcon />,
            color: '#FFBC00',
          },
          {
            title: 'Human Count',
            data: `${dashboard.toLocaleString('en-US')}`,
            icon: <TagUserIcon />,
            color: '#64D5D2',
          },
        ];

        setDashboardData(data);
      } else if (
        selectedCategory === 'peoplecounter' &&
        selectedType === 'month' &&
        !loading
      ) {
        console.log(analytics);
        var max = _.maxBy(Object.keys(analytics), (o) => {
          console.log(o, 'o');
          return _.sum(Object.values(analytics[o]));
        });
        var allData = Object.values(analytics).reduce((acc, b) => {
          return (acc += _.sum(Object.values(b)));
        }, 0);
        var todayData = allData / Object.keys(analytics).length;
        var dataDashboard = [
          {
            title: 'Human Count (All screens)',
            data: `${allData.toLocaleString('en-US')}`,
            icon: <TagUserIcon />,
            color: '#64D5D2',
          },
          {
            title: 'Average Humans per Day',
            data: `${Math.round(todayData).toLocaleString('en-US')}`,
            icon: <PeopleCount />,
            color: '#FFBC00',
          },
          {
            title: 'Peak Date (DD/MM/YYYY)',
            data: `${max}`,
            icon: <PeakTimeIcon />,
            color: '#FF7066',
          },
        ];

        setDashboardData(dataDashboard);
        // dispatch(calculateData({ data: rawAnalytics }));
      }
    }

    return () => {
      isCanceled = true;
    };
  }, [analytics]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategory = (type) => {
    const findCategory = reportType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleSetPlaylist = () => {
    setSelectedCategory(tempSelectedCategory);
    setPlaylistOpen(false);
    if (
      selectedType === 'month' ||
      tempSelectedCategory === 'profileanalyzer'
    ) {
      setGrouped();
    }
  };

  const getType = (type) => {
    console.log(type);
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleSetType = () => {
    if (selectedCategory === 'peoplecounter') {
      console.count('fetch analytics');
      dispatch(
        fetchAnalytics({
          start: dateRange.startDate,
          end: dateRange.endDate,
          type: tempSelectedType,
          day: date,
        })
      );
    }
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
    setTempSelectedLocation();
    setSelectedLocation();
    if (
      tempSelectedType === 'month' ||
      selectedCategory === 'profileanalyzer'
    ) {
      setGrouped();
    }
  };
  const getDuration = (type) => {
    console.log(type);
    const findCategory = durationType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleSetDuration = () => {
    if (selectedCategory === 'peoplecounter' && selectedType === 'day') {
      console.count('fetch analytics');
      // dispatch(
      //   fetchAnalytics({
      //     start: dateRange.startDate,
      //     end: dateRange.endDate,
      //     type: tempSelectedType,
      //     day: date,
      //   })
      // );
    }
    setSelectedDuration(tempSelectedDuration);
    setDurationOpen(false);
  };

  const getLocation = (type) => {
    console.log(type);
    const findCategory = locationList.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleSetLocation = () => {
    if (selectedCategory === 'profileanalyzer') {
      console.count('fetch analytics');
      dispatch(
        fetchProfileAnalytics({
          start: dateRange.startDate,
          end: dateRange.endDate,
          type: tempSelectedType,
          day: date,
          location:
            tempSelectedLocation === 'all' ? undefined : tempSelectedLocation,
        })
      );
    }
    if (selectedCategory === 'peoplecounter') {
      dispatch(
        fetchAnalytics({
          start: dateRange.startDate,
          end: dateRange.endDate,
          type: tempSelectedType,
          day: date,
          location:
            tempSelectedLocation === 'all' ? undefined : tempSelectedLocation,
        })
      );
    }
    setSelectedLocation(
      tempSelectedLocation === 'all' ? undefined : tempSelectedLocation
    );
    setLocationOpen(false);
  };

  const handleDateChange = (range) => {
    if (selectedType === 'month') {
      const startDate = range && range[0].valueOf();
      const endDate = range && range[1].valueOf();

      if (startDate === endDate) {
        dispatch(loadingAnalytic());

        setSelectedType('day');
        setDate(startDate);
        setTempSelectedType('day');
      } else {
        setDateRange((prevState) => ({
          ...prevState,
          startDate,
          endDate,
        }));
      }
    } else if (selectedType === 'day') {
      setDate(range.valueOf());
    }
  };

  return (
    <>
      <div className="analytic">
        <div className="analytic-header">
          {peakTime && (
            <ModalPeakTime
              visible={peakTime}
              onClose={() => setPeakTime(false)}
            />
          )}
          <div className="analytic-header-left">
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setPlaylistOpen(!playlistOpen)}
            >
              <div className="playlist-selection">
                <ForwardIcon />
                <span>
                  {!selectedCategory ? 'Type' : getCategory(selectedCategory)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={reportType}
                setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                tempSelectedCategory={tempSelectedCategory}
                modalOpen={playlistOpen}
                handleSetPlaylist={handleSetPlaylist}
              />
            </div>
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setTypeOpen(!typeOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>{!selectedType ? 'Type' : getType(selectedType)}</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  typeOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={chartType}
                modalOpen={typeOpen}
                handleSetPlaylist={handleSetType}
                setTempSelectedCategory={(id) => setTempSelectedType(id)}
                tempSelectedCategory={tempSelectedType}
                title={'Range'}
              />
            </div>
            {selectedCategory === 'peoplecounter' && selectedType === 'day' && (
              <div
                className="playlist-wrapper"
                onClick={() => !loading && setDurationOpen(!durationOpen)}
              >
                <div className="playlist-selection">
                  <ForwardIcon />
                  <span>
                    {!selectedDuration
                      ? 'Duration'
                      : getDuration(selectedDuration)}
                  </span>
                </div>
                <i
                  className={`feather-chevron-down ${
                    durationOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <CategoryDropdown
                  categories={durationType}
                  modalOpen={durationOpen}
                  handleSetPlaylist={handleSetDuration}
                  setTempSelectedCategory={(id) => setTempSelectedDuration(id)}
                  tempSelectedCategory={tempSelectedDuration}
                  title={'Interval'}
                />
              </div>
            )}

            {/** Category dropdown for location */}
            {
              <div
                className="playlist-wrapper"
                onClick={() => !loading && setLocationOpen(!locationOpen)}
              >
                <div className="playlist-selection">
                  <ForwardIcon />
                  <span>
                    {!selectedLocation
                      ? 'Location'
                      : getLocation(selectedLocation)}
                  </span>
                </div>
                <i
                  className={`feather-chevron-down ${
                    locationOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <CategoryDropdown
                  categories={locationList}
                  modalOpen={locationOpen}
                  handleSetPlaylist={handleSetLocation}
                  setTempSelectedCategory={(id) => setTempSelectedLocation(id)}
                  tempSelectedCategory={tempSelectedLocation}
                  filter={true}
                  title={'Location'}
                />
              </div>
            }

            <div>
              {selectedType && selectedType === 'month' ? (
                <DatePicker.RangePicker
                  className="analytic-date-picker"
                  onChange={handleDateChange}
                />
              ) : (
                <DatePicker
                  className="analytic-date-picker"
                  onChange={handleDateChange}
                  value={moment(new Date(date))}
                />
              )}
            </div>
          </div>
        </div>
        <div className="analytic-content-wrapper">
          <div className="analytic-content">
            {(selectedCategory === 'peoplecounter' ||
              selectedCategory === 'profileanalyzer') &&
              !loading && <DashboardCard data={dasboardData} />}
            {selectedCategory === 'peoplecounter' && analytics && (
              <PeopleCounterReport
                siteId={siteId}
                analytics={analytics}
                type={selectedType}
                loading={loading}
                date={date}
                duration={selectedDuration}
                rawData={rawAnalytics}
                getGroupedDaily={(dataGroup) => setGrouped(dataGroup)}
                onShowPeakTime={() => setPeakTime(true)}
              />
            )}
            {selectedCategory === 'profileanalyzer' && (
              <ProfileAnalyzerReport
                siteId={siteId}
                analytics={analytics}
                type={selectedType}
                loading={loading}
                date={date}
                locations={locations}
              />
            )}
            {!selectedCategory && (
              <div className="empty-content">
                <EmptyAnalyticIcon />
                <h1 className="empty-title">
                  Select the type of data analytics
                </h1>
                <p>
                  Please select the data type first to display the analytics
                  data to be shown
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { configureStore } from './state/store';
// import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';
import { GridProvider } from './components/Grid/GridContext';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// import './assets/css/main.css';
import './assets/css/style.css';
// import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
// import 'bulma-switch/dist/css/bulma-switch.min.css';
import 'antd/dist/antd.css';

import { createGlobalStyle } from 'styled-components';

const { width } = window.screen;

const { store, persistor } = configureStore({});
const GlobalStyles = createGlobalStyle`
  html {
    --header-color: rgba(247, 249, 252, 0.8);
    --color-primary: #64D5D2;
    --color-light-blue: #0371FF;
    --color-secondary: #B1BFCA;
    --color-stroke: #C3CAD9;
    --color-active: #21486C;
    --color-black: #4F4F4F;
    --color-grey: HSL(213, 5%, 39%);
    --color-light-grey: HSL(0, 0%, 76%);
    --color-orange: #FC7318;
    --color-background: #F7F8FA;
    --color-danger: #ec5b5b;
    --color-grey-menu: #C1C8CE;
    --screen-width: ${width - 340}px;
    --color-text-grey: hsla(0, 0%, 48%, 1);
    --color-navbar: rgba(247, 249, 252, 0.8);
    --table-name-color        : #00459E;
    --table-desc-color        : #171C26;
    --time-color        : #464F60;
    --action-color      : #A0ACB7;
    --primary-button    : #64D5D2;
    --warning-button: #FFBC00;
    --danger-button: #CB3A31;
    --color-blue-1: hsla(215, 69%, 23%, 1);
    --color-blue-2: hsla(214, 70%, 34%, 1);
    --color-blue-3: hsla(214, 70%, 45%, 1);
    --color-blue-4: hsla(214, 92%, 57%, 1);
    --color-blue-5: hsla(214, 92%, 65%, 1);
    --color-blue-6: hsla(214, 92%, 74%, 1);
    --color-blue-7: hsla(215, 91%, 83%, 1);
    --color-blue-8: hsla(215, 91%, 91%, 1);
    --color-label: #4A5568;
    --color-border-active: #53D7F5;

    --color-bg-sidebar: #163C50;
    --color-text-primary: #0851B5;
    --color-text-black: #404040;
    --color-text-gray: #A5A5A5;
    --color-text-gray-dark: #C2C2C2;
    --color-text-gray-light: #CCD2E3;
    --color-text-placeholder: #B3B3B3;
    --color-text-light: #9E9E9E;
    --color-border: #EDEDED;
    --color-bg-gray: #DCDCDC;
    --color-bg-white-blueish: #F9FAFD;
    --color-bg-primary: #FBFBFB;
    --color-text-gray-darker: #757575;

    --color-bg-navbar-active: rgba(255, 255, 255, 0.06);
    --color-bg-navbar-children-active: rgba(255, 255, 255, 0.06);
    --color-navbar-active-border: #64D5D2;

    --color-stroke-dark: #E0E0E0;
    --color-bg-footer: rgba(0, 0, 0, 0.04);

    --color-bg-radio: #F8F8F8;
    --color-border-radio: #E0E0E0;

    --color-bg-overlay: rgb(45,165,161,0.12);
    --color-input:  #F7F7F7;
    --color-input-focus: #2C82F6;

    --color-bg-pink: #FCD8D6;
    --color-bg-modal-header: #F9F9F9;
    --cancel-button: #EA2C20;
    --secondary-button: #2C82F6;

    --color-table-header: #687182;
    --color-border-table: #E9EDF5;
    --color-footer-table: #F7F9FC;

    --color-step: #E5E5E5;

  }
`;

const app = (
  <DndProvider backend={HTML5Backend}>
    <GlobalStyles />
    <GridProvider>
      <Provider store={store}>
        <LanguageWrapper>
          <PersistGate persistor={persistor}>
            <ReduxToastr
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              getState={(state) => state.toastr}
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
            <Router />
          </PersistGate>
        </LanguageWrapper>
      </Provider>
    </GridProvider>
  </DndProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();

import React from 'react';
import Loading from '../../assets/loading-animation.gif';
import { useState } from 'react';
import { useEffect } from 'react';

const LoadingAnimation = () => {
  const [index, setIndex] = useState(0);

  const placeholderText = [
    'We are fetching your data,\nplease wait for a few minutes',
    'If the page appears to be unresponsive,\nplease press wait',
    'Calculation process is in progress,\nplease wait for a few minutes',
  ];

  const timer = () => {
    setIndex((prevIndex) => {
      console.log(prevIndex, 'prev index');
      if (prevIndex === placeholderText.length - 1) {
        return 0;
      }
      return prevIndex + 1;
    });
  };
  useEffect(() => {
    let interval;
    interval = setInterval(timer, 5000);

    return () => {
      console.log('clear interval return');
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="loading-wrapper">
      <img className="loading-animation" src={Loading} alt="...loading" />
      <div className="loading-desc">{placeholderText[index]}</div>
    </div>
  );
};

export default LoadingAnimation;

import React from 'react';

const DashboardCard = (props) => {
  const { data } = props;
  return (
    <div
      className="analytic-dashboard"
      style={{ gridTemplateColumns: `repeat(${data.length}, 1fr)` }}
    >
      {data.map((d, index) => (
        <div key={index} className="dashboard-card">
          <div className="dashboard-icon" style={{ backgroundColor: d.color }}>
            {d.icon}
          </div>
          <div className="dashboard-data">
            <h2>{d.title}</h2>
            <h4>{d.data}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCard;

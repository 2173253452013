import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const MEDIA_FETCH_DATA_INIT = createAction('MEDIA_FETCH_DATA_INIT');
export const MEDIA_FETCH_DATA_SUCCESS = createAction(
  'MEDIA_FETCH_DATA_SUCCESS'
);
export const MEDIA_FETCH_DATA_FAIL = createAction('MEDIA_FETCH_DATA_FAIL');

export const MEDIA_DELETE_MEDIA_INIT = createAction('MEDIA_DELETE_MEDIA_INIT');
export const MEDIA_DELETE_MEDIA_SUCCESS = createAction(
  'MEDIA_DELETE_MEDIA_SUCCESS'
);
export const MEDIA_DELETE_MEDIA_FAIL = createAction('MEDIA_DELETE_MEDIA_FAIL');

export const BULK_MEDIA_DELETE_INIT = createAction('BULK_MEDIA_DELETE_INIT');
export const BULK_MEDIA_DELETE_SUCCESS = createAction(
  'BULK_MEDIA_DELETE_SUCCESS'
);
export const BULK_MEDIA_DELETE_FAIL = createAction('BULK_MEDIA_DELETE_FAIL');

export const MEDIA_CLEAR_DATA = createAction('MEDIA_CLEAR_DATA');

export const MEDIA_CREATE_MEDIA_INIT = createAction('MEDIA_CREATE_MEDIA_INIT');
export const MEDIA_CREATE_MEDIA_SUCCESS = createAction(
  'MEDIA_CREATE_MEDIA_SUCCESS'
);
export const MEDIA_CREATE_MEDIA_FAIL = createAction('MEDIA_CREATE_MEDIA_FAIL');

export const MEDIA_MODIFY_MEDIA_INIT = createAction('MEDIA_MODIFY_MEDIA_INIT');
export const MEDIA_MODIFY_MEDIA_SUCCESS = createAction(
  'MEDIA_MODIFY_MEDIA_SUCCESS'
);
export const MEDIA_MODIFY_MEDIA_FAIL = createAction('MEDIA_MODIFY_MEDIA_FAIL');

export const MEDIA_CLEAN_UP = createAction('MEDIA_CLEAN_UP');

export const MEDIA_CLEAR_DATA_LOGOUT = createAction('MEDIA_CLEAR_DATA_LOGOUT');

export const MEDIA_BULK = createAction('MEDIA_BULK');

// added by me
export const SET_MEDIA = createAction('SET_MEDIA');
export const ORDER_MEDIA = createAction('ORDER_MEDIA');
export const PASTE_MEDIA = createAction('PASTE_MEDIA');

export const fetchMedia = ({ mediaId, siteId, categoryId, type }) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_FETCH_DATA_INIT());
    if (mediaId) {
      let mediaData;
      try {
        mediaData = await Axios.get(`${url}/media/get`, {
          params: {
            id: mediaId,
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIA_FETCH_DATA_FAIL({ error }));
      }
      const media = {
        ...mediaData.data.data,
        id: mediaId,
        categoryList: [
          {
            label: mediaData.data.data.categoryName,
            value: mediaData.data.data.categoryId,
          },
        ],
      };
      return dispatch(
        MEDIA_FETCH_DATA_SUCCESS({
          list: getState().media.list,
          media,
        })
      );
    }

    if (siteId) {
      let media;
      try {
        media = await Axios.post(
          `${url}/media/filter`,
          { siteId, categoryId, type },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIA_FETCH_DATA_FAIL({ error }));
      }
      // media = media.data.data.filter((media) => media.siteId === siteId);
      return dispatch(
        MEDIA_FETCH_DATA_SUCCESS({
          list: media.data.data,
          media: getState().media.media,
        })
      );
    }
  };
};

export const bulkDeleteMedia = ({ siteId, data }, cb) => {
  return async (dispatch, getState) => {
    dispatch(BULK_MEDIA_DELETE_INIT());

    try {
      await Axios.post(
        `${url}/media/bulk-delete`,
        {
          siteId,
          data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        BULK_MEDIA_DELETE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The media was deleted.');
    cb();
    return dispatch(BULK_MEDIA_DELETE_SUCCESS({ data }));
  };
};

export const removeMedia = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(MEDIA_DELETE_MEDIA_INIT());
    try {
      await Axios.post(
        `${url}/media/remove`,
        {
          id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_DELETE_MEDIA_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The media was removed.');
    cb();
    return dispatch(MEDIA_DELETE_MEDIA_SUCCESS({ id }));
  };
};

export const deleteMedia = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(MEDIA_DELETE_MEDIA_INIT());

    try {
      await Axios.delete(`${url}/media/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_DELETE_MEDIA_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The media was deleted.');
    cb();
    return dispatch(MEDIA_DELETE_MEDIA_SUCCESS({ id }));
  };
};

export const clearMediaData = () => {
  return (dispatch) => {
    dispatch(MEDIA_CLEAR_DATA());
  };
};

export const clearMediaDataLogout = () => {
  return (dispatch) => {
    dispatch(MEDIA_CLEAR_DATA_LOGOUT());
  };
};

export const createMedia = (
  {
    uid,
    name,
    description,
    start,
    end,
    duration,
    file,
    fileUrl,
    siteId,
    categoryId,
    categoryName,
    type,
    days,
    dates,
    color,
    modifiedAt,
    modifiedBy,
    categoryList,
  },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_CREATE_MEDIA_INIT());

    console.log(start, end, 'start end before post');

    let fd = new FormData();
    fd.set('path', 'media');
    fd.set('name', name);
    fd.set('description', description);
    if (start && end && start !== 'Invalid date' && end !== 'Invalid date') {
      fd.set('start', start);
      fd.set('end', end);
    }
    fd.set('duration', duration);
    fd.set('siteId', siteId);
    if (categoryId) {
      fd.set('categoryId', categoryId);
      // fd.set('categoryName', categoryName);
    }
    fd.set('dates', JSON.stringify(dates));
    fd.set('days', JSON.stringify(days));
    fd.set('type', type);
    fd.set('color', color);
    fd.set('categoryList', JSON.stringify(categoryList));
    fd.append('file', file);
    let createMedia;
    try {
      createMedia = await Axios.post(`${url}/mediaDirectory/upload`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
      log(createMedia, '<<<< create mediaaa');
    } catch (error) {
      log(error.response, '<<<< error add media');
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');

      return dispatch(
        MEDIA_CREATE_MEDIA_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Media created successfully');
    console.log(createMedia, 'createMedia');
    if (cb) {
      cb();
    }
    return dispatch(
      MEDIA_CREATE_MEDIA_SUCCESS({ media: createMedia.data.data })
    );
  };
};

export const modifyMedia = (
  {
    id,
    name,
    description,
    start,
    end,
    duration,
    categoryId,
    categoryName,
    file,
    fileUrl,
    siteId,
    modifiedAt,
    modifiedBy,
    type,
    days,
    dates,
    color,
  },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_MODIFY_MEDIA_INIT());
    log(categoryId, dates);
    let fd = new FormData();

    fd.set('path', 'media');
    fd.set('id', id);
    fd.set('name', name);
    fd.set('description', description);
    if (start && end && start !== 'Invalid date' && end !== 'Invalid date') {
      fd.set('start', start);
      fd.set('end', end);
    }
    if (duration) {
      fd.set('duration', duration);
    }
    fd.set('siteId', siteId);
    if (categoryId) {
      fd.set('categoryId', categoryId);
    }
    if (categoryName) {
      fd.set('categoryName', categoryName);
    }
    fd.set('dates', JSON.stringify(dates));
    fd.set('days', JSON.stringify(days));
    fd.set('type', type);
    fd.set('color', color);
    fd.append('file', file);
    let updateMedia;
    log(fd.toString(), 'request body');

    try {
      updateMedia = await Axios.post(`${url}/media/edit`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_MODIFY_MEDIA_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Media updated successfully');
    if (cb) {
      cb();
    }

    return dispatch(
      MEDIA_MODIFY_MEDIA_SUCCESS({ media: updateMedia.data.data, id })
    );
  };
};

export const bulkMedia = ({ data, siteId }) => {
  log('Im called before dispatch');
  return async (dispatch, getState) => {
    log('Im called after dispatch');
    const mediaBulkData = await Axios.post(
      `${url}/media/bulk`,
      {
        data,
        siteId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return dispatch(MEDIA_BULK({ medias: mediaBulkData.data.data }));
  };
};

// added by me
export const setMedia = (media) => {
  return (dispatch) => {
    dispatch(SET_MEDIA({ media }));
  };
};

export const orderMedia = (media) => {
  return (dispatch) => {
    dispatch(ORDER_MEDIA({ media }));
  };
};

export const mediaCleanUp = () => (dispatch) => dispatch(MEDIA_CLEAN_UP());

export const mediaPaste = (
  { directoryList, siteId, categoryId, categoryName },
  cb
) => {
  return async (dispatch) => {
    await Axios.post(
      `${url}/media/create`,
      {
        directoryList,
        siteId,
        categoryId,
        categoryName,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    cb();
  };
};

export const bulkUpdate = ({ start, end, ids, siteId }, cb) => {
  return async (dispatch) => {
    await Axios.put(
      `${url}/media/bulk `,
      {
        start,
        end,
        ids,
        siteId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    cb();
  };
};

export const bulkApproval = ({ status, ids, siteId }, cb) => {
  return async (dispatch) => {
    await Axios.post(
      `${url}/media/approval `,
      {
        status,
        ids,
        siteId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    cb();
  };
};

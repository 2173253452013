import { Modal, Table } from 'antd';
import LoadingAnimation from 'components/LoadingAnimation';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

export default function ModalPeakTime({ visible, onClose }) {
  const { loading, rawData } = useSelector(
    (state) => ({
      loading: state.rawAnalytic.loadingCalculate,
      rawData: state.rawAnalytic.rawData,
    }),
    shallowEqual
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      width: '10%',
      render: (text, record, index) => {
        return {
          props: {
            style: {
              background:
                index === 0 || record.y === rawData[0].y
                  ? '#F2FFEC'
                  : '#FFFFFF',
            },
          },
          children: <div className="table-name">{index + 1}</div>,
        };
      },
    },
    {
      title: 'TIME',
      dataIndex: 'x',
      width: '40%',
      render: (text, record, index) => {
        return {
          props: {
            style: {
              background:
                index === 0 || record.y === rawData[0].y
                  ? '#F2FFEC'
                  : '#FFFFFF',
            },
          },
          children: <div className="table-name">{text}</div>,
        };
      },
    },
    {
      title: 'PEOPLE',
      dataIndex: 'y',
      width: '50%',
      render: (text, record, index) => {
        return {
          props: {
            style: {
              background:
                index === 0 || record.y === rawData[0].y
                  ? '#F2FFEC'
                  : '#FFFFFF',
            },
          },
          children: <div className="table-name">{text.toLocaleString()}</div>,
        };
      },
    },
  ];

  return (
    <Modal
      className="analytic-modal"
      visible={visible}
      title={'Detail'}
      onCancel={onClose}
      footer={null}
    >
      <div className="analytic-modal-body">
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <h3> Peak Time Period (Hourly Ranking)</h3>
            <Table
              columns={columns}
              dataSource={rawData}
              pagination={false}
              style={{ width: '100%' }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

import { createReducer } from 'redux-act';

import {
  TEMPLATE_FETCH_DATA_INIT,
  TEMPLATE_FETCH_DATA_SUCCESS,
  TEMPLATE_FETCH_DATA_FAIL,
  TEMPLATE_DELETE_TEMPLATE_INIT,
  TEMPLATE_DELETE_TEMPLATE_SUCCESS,
  TEMPLATE_DELETE_TEMPLATE_FAIL,
  TEMPLATE_CLEAR_DATA,
  TEMPLATE_CREATE_TEMPLATE_INIT,
  TEMPLATE_CREATE_TEMPLATE_SUCCESS,
  TEMPLATE_CREATE_TEMPLATE_FAIL,
  TEMPLATE_MODIFY_TEMPLATE_INIT,
  TEMPLATE_MODIFY_TEMPLATE_SUCCESS,
  TEMPLATE_MODIFY_TEMPLATE_FAIL,
  TEMPLATE_CLEAN_UP,
  TEMPLATE_CLEAR_DATA_LOGOUT,
  TEMPLATE_EXPORT_TEMPLATE_INIT,
  TEMPLATE_EXPORT_TEMPLATE_SUCCESS,
  TEMPLATE_EXPORT_TEMPLATE_FAIL,
} from 'state/actions/template';

const initialState = {
  list: [],
  template: {
    name: '',
    width: 1920,
    height: 1080,
    description: '',
    orientation: 'portrait',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  modified: false,
  deleted: false,
};

export const templateReducer = createReducer(
  {
    [TEMPLATE_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TEMPLATE_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      template: payload.template || initialState.template,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TEMPLATE_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEMPLATE_DELETE_TEMPLATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEMPLATE_DELETE_TEMPLATE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [TEMPLATE_DELETE_TEMPLATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEMPLATE_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [TEMPLATE_CREATE_TEMPLATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEMPLATE_CREATE_TEMPLATE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.template),
      loading: false,
      template: payload.template,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [TEMPLATE_CREATE_TEMPLATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEMPLATE_MODIFY_TEMPLATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEMPLATE_MODIFY_TEMPLATE_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.template.name,
                location: payload.template.location,
                id: payload.id,
                logoUrl: payload.template.logoUrl,
                createdAt: payload.template.createdAt,
                email: elem.email,
                phone: payload.template.phone,
                priority: payload.template.priority,
                description: payload.template.description,
                logo: null,
                opentime: payload.template.opentime,
                url: payload.template.url,
                category: payload.template.category,
                siteId: payload.template.site,
                screenId: payload.template.screenId,
                tag: payload.template.tag,
                floor: payload.template.floor,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.template.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      modified: true,
    }),
    [TEMPLATE_MODIFY_TEMPLATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEMPLATE_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [TEMPLATE_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [TEMPLATE_EXPORT_TEMPLATE_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [TEMPLATE_EXPORT_TEMPLATE_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TEMPLATE_EXPORT_TEMPLATE_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);

import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const MEDIA_CATEGORIES_FETCH_DATA_INIT = createAction(
  'MEDIA_CATEGORIES_FETCH_DATA_INIT'
);
export const MEDIA_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'MEDIA_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const MEDIA_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'MEDIA_CATEGORIES_FETCH_DATA_FAIL'
);

export const MEDIA_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'MEDIA_CATEGORIES_DELETE_CATEGORY_INIT'
);
export const MEDIA_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'MEDIA_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const MEDIA_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'MEDIA_CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const MEDIA_CATEGORIES_CLEAR_DATA = createAction(
  'MEDIA_CATEGORIES_CLEAR_DATA'
);

export const MEDIA_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'MEDIA_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const MEDIA_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'MEDIA_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const MEDIA_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'MEDIA_CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const MEDIA_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'MEDIA_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const MEDIA_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'MEDIA_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const MEDIA_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'MEDIA_CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const MEDIA_CATEGORIES_CLEAN_UP = createAction(
  'MEDIA_CATEGORIES_CLEAN_UP'
);

export const MEDIA_CATEGORIES_CLEAR_DATA_LOGOUT = createAction(
  'MEDIA_CATEGORIES_CLEAR_DATA_LOGOUT'
);

export const SET_MEDIA_CATEGORY = createAction('SET_MEDIA_CATEGORY');

export const fetchMediaCategories = ({ categoryId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(MEDIA_CATEGORIES_FETCH_DATA_INIT());
    if (categoryId) {
      let categoryData;
      try {
        categoryData = await Axios.get(`${url}/category`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIA_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      const category = { ...categoryData, id: categoryId };
      return dispatch(
        MEDIA_CATEGORIES_FETCH_DATA_SUCCESS({
          list: getState().mediaCategories.list,
          category,
        })
      );
    }

    if (siteId) {
      let categories;
      try {
        categories = await Axios.get(`${url}/category`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIA_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      categories = categories.data.data.filter(
        (category) => category.siteId === siteId
      );
      return dispatch(
        MEDIA_CATEGORIES_FETCH_DATA_SUCCESS({
          list: categories,
          category: getState().mediaCategories.category,
        })
      );
    }
  };
};

export const deleteMediaCategory = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_CATEGORIES_DELETE_CATEGORY_INIT());

    try {
      await Axios.delete(`${url}/category/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The category was deleted.');
    cb();
    return dispatch(MEDIA_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearCategoriesData = () => {
  return (dispatch) => {
    dispatch(MEDIA_CATEGORIES_CLEAR_DATA());
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(MEDIA_CATEGORIES_CLEAR_DATA_LOGOUT());
  };
};

export const createMediaCategory = (
  {
    uid,
    name,
    description,
    orientation,
    siteId,
    width,
    height,
    modifiedAt,
    modifiedBy,
  },
  setIsModalCategoryShow
) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_CATEGORIES_CREATE_CATEGORY_INIT());
    let createCategory;
    const category = {
      name,
      description,
      orientation,
      siteId,
    };
    if (width) {
      category.width = width;
    }
    if (height) {
      category.height = height;
    }

    try {
      createCategory = await Axios.post(`${url}/category/create`, category, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      log(createCategory, '<<< created cat');
    } catch (error) {
      log(error.response, '<<< ERROR CREATE MEDIA CATEGORY');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_CATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    setIsModalCategoryShow(false);
    return dispatch(
      MEDIA_CATEGORIES_CREATE_CATEGORY_SUCCESS({
        category: createCategory.data.data,
      })
    );
  };
};

export const modifyMediaCategory = (
  {
    id,
    name,
    description,
    orientation,
    siteId,
    height,
    width,
    modifiedAt,
    modifiedBy,
  },
  setIsModalCategoryShow
) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_CATEGORIES_MODIFY_CATEGORY_INIT());

    const categoryData = {
      id,
      name,
      description,
      orientation,
      siteId,
      modifiedAt,
      modifiedBy,
    };
    if (width) {
      categoryData.width = width;
    }
    if (height) {
      categoryData.height = height;
    }

    console.log(categoryData, 'category data');

    try {
      await Axios.post(`${url}/category/edit`, categoryData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category updated successfully');
    setIsModalCategoryShow(false);

    return dispatch(
      MEDIA_CATEGORIES_MODIFY_CATEGORY_SUCCESS({
        mediaCategory: categoryData,
        id,
      })
    );
  };
};

export const categoriesCleanUp = () => (dispatch) =>
  dispatch(MEDIA_CATEGORIES_CLEAN_UP());

// added by me
export const setMediaCategory = (data) => {
  return (dispatch) => {
    dispatch(
      SET_MEDIA_CATEGORY({
        data,
      })
    );
    // cb();
  };
};

import { createIntl, createIntlCache } from 'react-intl';
import crypto from 'crypto';
import publicIp from 'public-ip';

import axios from 'axios';

import english from 'languages/en';
import en from 'assets/en.png';
import { logout } from 'state/actions/auth';

export const messages = {
  en: english,
};

export const log = (...args) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};

export const trimString = (string, length) => {
  return string && string.length > length
    ? `${string.substring(0, length)}...`
    : string;
};

const initAuth = () => {
  return window.gapi.auth2.init({
    client_id:
      '679571380718-fikfqnflp10uf4fmcmqpccif0rougchh.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/analytics.readonly',
  });
};

export const onKeyPressValidationForEmail = (event) => {
  const requiredPattern = /[a-zA-Z0-9.-@]+/;
  if (!requiredPattern.test(event.key)) {
    event.preventDefault();
  }
};

export const onKeyPressValidation = (event) => {
  const requiredPattern = /[a-zA-Z0-9.-@]+/;
  if (!requiredPattern.test(event.key)) {
    event.preventDefault();
  }
};

export const checkSignedIn = () => {
  return new Promise((resolve, reject) => {
    initAuth() //calls the previous function
      .then(() => {
        const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
        resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const renderButton = () => {
  window.gapi.signin2.render('signin-button', {
    scope: 'profile email',
    width: 240,
    height: 50,
    longtitle: true,
    theme: 'dark',
    onsuccess: onSuccess,
    onfailure: onFailure,
  });
};

const onSuccess = (googleUser) => {
  log('Logged in as: ' + googleUser.getBasicProfile().getName());
};

const onFailure = (error) => {
  console.error(error);
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const validateEmail = (email) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|asia|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
  );
};

export const validatePassword = (password) => {
  return password.new.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);
};

export const inputValidations = (email, password, locale) => {
  let inputs = {
    email: {
      modifier: null,
      message: null,
    },
    password: {
      modifier: null,
      message: null,
    },
    canSubmit: null,
  };
  const intl = getIntlContext(locale);

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isValidEmail = email && validateEmail(email);

  if (email && !isValidEmail) {
    setInputs('email', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.invalidEmail' }),
    });
  }

  const isValidPassword = password && password.length >= 6;

  if (isValidPassword) {
    setInputs('password', {
      modifier: 'is-success',
      message: intl.formatMessage({ id: 'utils.safePassword' }),
    });
  } else if (password) {
    setInputs('password', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.unsafePassword' }),
    });
  }

  if (isValidEmail && isValidPassword) {
    setInputs('canSubmit', true);
  }

  return inputs;
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
};

export const generatePassword = (
  length = 20,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) =>
  Array.from(crypto.randomFillSync(new Uint8Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('');

export const sendMail = async (email, password) => {
  log('sending mail');
  let sendStatus;
  try {
    sendStatus = await axios({
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      method: 'post',
      url: 'https://analytics.adactive.asia/mail/send',
      data: {
        email: email,
        password: password,
      },
    });
    log(sendStatus);
  } catch (e) {
    log(e);
  }
  return sendStatus;
};

export const errorMsg = (dispatch, error) => {
  let errorMessage;

  if (
    error &&
    error.response &&
    error &&
    error.response &&
    error.response.data &&
    error &&
    error.response &&
    error.response.data.message
  ) {
    errorMessage = error && error.response && error.response.data.message;
    dispatch(logout());
  }
  if (!errorMessage) {
    errorMessage =
      'Attempting to retrieve the information, please do not refresh the page. If data is not shown persistently, please do refresh.';
  }
  return errorMessage;
};

export const getClientIp = async () => {
  const ip = await publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip'],
  });
  return ip;
};

export const getAccess = (userData, type, siteId) => {
  const access = userData.roleAccess.access.filter(
    (acc) => acc.siteId === siteId
  );
  if (access) {
    switch (type) {
      case 'media':
        return access[0].media;
      case 'tenant':
        return access[0].poi;
      case 'information':
        return access[0].information;
      default:
        return null;
    }
  } else {
    return null;
  }
};

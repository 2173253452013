import { Table } from 'antd';
import { PeakTimeButton } from 'assets/icon';
import LoadingAnimation from 'components/LoadingAnimation';
import {
  ChartTitle,
  ChartWrapper,
  ReportWrapper,
} from 'components/Report/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Bar, Line } from 'react-chartjs-2';
// import ScreensaverReport from './ScreensaverReport';

const PeopleCounterReport = (props) => {
  const {
    analytics,
    type,
    loading,
    date,
    duration,
    getGroupedDaily,
    onShowPeakTime,
  } = props;
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };

  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [dataTable, setDataTable] = useState([]);

  // const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  // const [endDate, setEndDate] = useState(new Date());

  const [loadingProcess, setLoadingProcess] = useState(false);

  // useEffect(() => {
  //   if (rawData.length > 0) {

  //   }
  // }, [rawData]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [optionsDay, setOptionDay] = useState({
  //   tooltips: {
  //     // displayColors: true,
  //     callbacks: {
  //       mode: 'x',
  //     },
  //   },

  //   scales: {
  //     xAxes: [
  //       {
  //         type: 'time',
  //         parser: 'YYYY-MM-DD HH:mm:ss',
  //         time: {
  //           unit: 'minute',
  //           parser: 'YYYY-MM-DD HH:mm:ss',
  //         },
  //         ticks: {
  //           // min: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  //           maxTicksLimit: 1440 / duration,
  //         },
  //         // max: moment().format("YYYY-MM-DD HH:mm"),
  //         stacked: false,
  //         gridLines: {
  //           display: true,
  //         },
  //       },
  //     ],
  //   },
  //   maintainAspectRatio: false,
  //   legend: { position: 'bottom' },
  //   plugins: {
  //     datalabels: {
  //       font: {
  //         size: 0,
  //       },
  //     },
  //   },
  // });
  const options = {
    tooltips: {
      displayColors: true,
      callbacks: {
        mode: 'x',
      },
    },
    // events: [],
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    animation: {
      duration: 1,
      onComplete() {
        const chartInstance = this.chart;
        const { ctx } = chartInstance;

        // ctx.font = Chart.helpers.fontString(
        //   Chart.defaults.global.defaultFontSize,
        //   Chart.defaults.global.defaultFontStyle,
        //   Chart.defaults.global.defaultFontFamily
        // );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        console.log(this, 'on complete data');
        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          console.log(meta, 'meta');
          if (!meta.hidden) {
            meta.data.forEach(function (bar, index) {
              if (dataset.data[index] > 0) {
                const data = dataset.data[index];
                ctx.font = 'bold 12pt roboto';
                ctx.fillText(data, bar._model.x, bar._model.y);
              }
            });
          }
        });
      },
    },
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    layout: {
      padding: 20,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const optionsDay = {
    tooltips: {
      // displayColors: true,
      callbacks: {
        mode: 'x',
      },
    },

    scales: {
      xAxes: [
        {
          type: 'time',
          parser: 'YYYY-MM-DD HH:mm:ss',
          time: {
            unit: 'minute',
            parser: 'YYYY-MM-DDTHH:mm:ss',
          },
          ticks: {
            min: date
              ? moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
              : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            maxTicksLimit: 1440 / duration,
          },
          // max: moment().format("YYYY-MM-DD HH:mm"),
          stacked: false,
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: { display: true, position: 'bottom' },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const data = reportData && {
    labels: reportData.labels,
    datasets: reportData.datasets,
  };
  console.log(data, 'data');

  // useEffect(() => {
  //   setOptionDay({
  //     tooltips: {
  //       // displayColors: true,
  //       callbacks: {
  //         mode: 'x',
  //       },
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           type: 'time',
  //           parser: 'YYYY-MM-DD HH:mm:ss',
  //           time: {
  //             unit: 'minute',
  //             parser: 'YYYY-MM-DD HH:mm:ss',
  //           },
  //           ticks: {
  //             // min: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  //             maxTicksLimit: 1440 / duration,
  //           },
  //           // max: moment().format("YYYY-MM-DD HH:mm"),
  //           stacked: false,
  //           gridLines: {
  //             display: true,
  //           },
  //         },
  //       ],
  //     },
  //     maintainAspectRatio: false,
  //     legend: { position: 'bottom' },
  //     plugins: {
  //       datalabels: {
  //         font: {
  //           size: 0,
  //         },
  //       },
  //     },
  //   });

  //   // setTimeout(
  //   //   () =>
  //   //     getData(),
  //   //   1100
  //   // );
  // }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   getData();
  // }, []);

  // const handleDateChange = (range) => {
  //   console.log(range.valueOf(), 'range');

  //   if (type === 'month') {
  //     const startDate = range && range[0].valueOf();
  //     const endDate = range && range[1].valueOf();

  //     setDateRange((prevState) => ({
  //       ...prevState,
  //       startDate,
  //       endDate,
  //     }));
  //   } else if (type === 'day') {
  //     setDate(range.valueOf());
  //   }
  // };

  // console.log(
  //   Object.values(analytics).map((set) => {
  //     console.log(set, "set");
  //     if (Object.keys(set).length > 0) {
  //       return Object.keys(set).map((loc) => {
  //         console.log(loc, ";loc");
  //         return {
  //           label: loc,
  //           data: set[loc],
  //           borderWidth: 1,
  //           backgroundColor: "#64D5D2",
  //         };
  //       });
  //     } else {
  //       return [];
  //     }
  //   }),
  //   "data"
  // );

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      width: '10%',
      render: (text, record, index) => (
        <div className="table-name">{index + 1}</div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '40%',
      render: (text, record) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Total Human Count',
      dataIndex: 'data',
      width: '20%',
      render: (text, record) => (
        <div className="table-name">{text.toLocaleString()}</div>
      ),
    },
  ];

  const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

  const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

  const colorPallete = [
    '#2C82F6',
    '#BFD9FC',
    '#7FB1F9',
    '#C6DFFD',
    '#1F69D8',
    '#1B5DBB',
  ];

  console.count('mounted people counter');
  useEffect(() => {
    if (analytics) {
      console.log(analytics, 'analytics');
      setLoadingProcess(true);
      if (type === 'month') {
        console.count('count useeffect');
        const locations = [];
        const analyticValue = Object.values(analytics);
        for (const val of analyticValue) {
          const keys = Object.keys(val);
          for (const loc of keys) {
            if (locations.indexOf(loc) === -1) {
              locations.push(loc);
            }
          }
        }
        // Object.values(analytics).forEach((val) => {
        //   Object.keys(val).forEach((loc) => {
        //     if (locations.indexOf(loc) === -1) {
        //       locations.push(loc);
        //     }
        //   });
        // });

        let monthData = [];

        setReportData({
          labels: Object.keys(analytics),
          datasets: locations.map((set, index) => {
            const color = colorPallete[index];
            let totalAnalytic = 0;

            for (let i = 0, n = analyticValue.length; i < n; i++) {
              const dataValue = analyticValue[i];
              if (dataValue) {
                analyticValue[i] = dataValue[set];
                if (analyticValue[i]) {
                  totalAnalytic += analyticValue[i];
                }
              }
              console.log(totalAnalytic, 'for loop count');
            }
            monthData.push({
              location: set,
              data: totalAnalytic,
              // data: Object.values(analytics)
              //   .map((val) => val[set])
              //   ?.reduce((a, b) => (b ? a + b : a), 0),
            });
            // let values = Object.values(analytics);
            // for (let val in values) {
            //   values[val] = values[val][set];
            // }
            // values.forEach((val, ind) => (values[ind] = val[set]));

            return {
              label: set,
              data: analyticValue,
              // data: Object.values(analytics).map((val) => val[set]),
              borderWidth: 1,
              backgroundColor: color,
              strokeColor: color,
            };
          }),
        });
        monthData = _.orderBy(monthData, 'data', 'desc');
        setDataTable(monthData);
      } else {
        const dataDay = [];
        const grouped = _.mapValues(
          _.groupBy(analytics, 'location'),
          (dlist, loc) => {
            console.log(dlist, loc, 'dlist');

            const allDay = dlist.map((time) => {
              return {
                x: moment(new Date(time.date)).format('YYYY-MM-DD HH:mm'),
                y: time.count,
              };
            });
            const startTime = date
              ? moment(new Date(date)).startOf('day').add(duration, 'minutes')
              : moment().startOf('day').add(duration, 'minutes');
            const endTime = date
              ? moment(new Date(date)).endOf('day')
              : moment().endOf('day');
            const groupedDuration = [];
            for (
              let d = moment(new Date(startTime));
              d.isSameOrBefore(endTime);
              d.add(duration, 'minutes')
            ) {
              const startLoop = moment(new Date(d)).subtract(
                duration,
                'minutes'
              );

              const dates = allDay.filter(
                (all) =>
                  moment(new Date(all.x)).isSameOrBefore(d, 'minute') &&
                  moment(new Date(all.x)).isAfter(startLoop, 'minute')
              );
              // d = d.add(duration, "minutes");
              console.log(dates, 'date');

              let groupedY = 0;
              for (let date = 0; date < dates.length; date++) {
                if (date === 0) {
                  groupedY += dates[date].y;
                } else {
                  const next = dates[date + 1];
                  if (next) {
                    if (next.y > dates[date].y) {
                      groupedY += next.y - dates[date].y;
                    } else {
                      console.log(groupedY + next.y, 'grouped y add');
                      groupedY += next.y;
                    }
                  }
                }
              } // eslint-disable-line

              groupedDuration.push({
                x: d.format('YYYY-MM-DD HH:mm'),
                y: groupedY,
                // y: dates.reduce((acc, val, i, arr) => {
                //   if (i === 0) {
                //     return acc + val.y;
                //   } else {
                //     const next = arr[i + 1];
                //     if (next) {
                //       if (next.y > val.y) {
                //         return acc + (next.y - val.y);
                //       } else {
                //         return acc + next.y;
                //       }
                //     } else {
                //       return acc;
                //     }
                //   }
                // }, 0),
              });
            }

            const totaldata = groupedDuration.reduce((a, b) => a + b.y, 0);
            dataDay.push({
              location: loc,
              data: totaldata,
            });

            return groupedDuration;
          }
        );

        setDataTable(dataDay);

        getGroupedDaily(grouped);
        setReportData({
          labels: Object.keys(grouped),
          datasets: Object.keys(grouped).map((set) => {
            const color = randomRGB();
            return {
              label: set,
              data: grouped[set],
              // fill: false,
              borderWidth: 2,
              backgroundColor: color,
              strokeColor: color,
            };
          }),
        });
      }
      setLoadingProcess(false);

      // clearInterval(timerInterval);
    }
  }, [analytics, duration]); // eslint-disable-line
  return (
    <div className="App">
      {/* <div className="field-body"> */}

      {/* </div> */}
      <ReportWrapper>
        <div className="people-counter-title">
          <ChartTitle>Traffic Counter Report</ChartTitle>
          {type === 'month' && (
            <div className="peak-time button" onClick={onShowPeakTime}>
              <PeakTimeButton />
              Show Peak Time
              <i className="feather-chevron-right" />
            </div>
          )}
        </div>

        {/* <div className="selector">
          {type === 'day' && (
            <div className="m-2">
              <Select
                placeholder={'duration'}
                style={{ width: 120 }}
                onChange={(val) => {
                  setReportData();
                  setDuration(val);
                }}
                options={[
                  { value: 10, label: '10 minutes' },
                  { value: 15, label: '15 minutes' },
                  { value: 30, label: '30 minutes' },
                  { value: 60, label: '1 hour' },
                ]}
              />
            </div>
          )}
        </div> */}
        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{"Start Date"}</label>
          </div>
          <div className="field-label is-normal">
            <label className="label">{"End Date"}</label>
          </div>
        </div> */}
        {reportData && !loading && !loadingProcess ? (
          <ChartWrapper>
            {type === 'month' ? (
              <Bar
                data={reportData}
                width={100}
                height={250}
                options={options}
              />
            ) : (
              <Line
                data={reportData}
                width={100}
                height={250}
                options={optionsDay}
              />
            )}
          </ChartWrapper>
        ) : (
          <>
            <LoadingAnimation />
          </>
        )}
      </ReportWrapper>
      <Table columns={columns} dataSource={dataTable} />
    </div>
  );
};

export default PeopleCounterReport;

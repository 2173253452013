/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker, Input, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { poisCleanUp } from 'state/actions/pois';
import { regexDescription, regexInput } from 'utils/regex';
import {
  createFloor,
  fetchFloor,
  modifyFloor,
  setFloor,
} from 'state/actions/floor';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import './PoiForm.css';
import './PoiForm.scss';
import {
  createCategory,
  fetchCategories,
  modifyCategory,
  setCategory,
} from 'state/actions/categories';
import moment from 'moment';
import { ExportIcon } from 'assets/icon/dynamic';
import CategoryDropdown from 'components/CategoryDropdown';
import ModalCategoryForm from 'components/ModalCategoryForm';
import ModalFloorForm from 'components/ModalFloorForm';

const PoiForm = ({ poi, setPoi, action, success, history, id, error }) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);
  const [tempSelectedCategory, setTempSelectedCategory] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [tempSelectedFloor, setTempSelectedFloor] = useState();
  const [modalFloor, setModalFloor] = useState(false);
  const [floorOpen, setFloorOpen] = useState(false);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const {
    loading,
    userData,
    categoriesList,
    floorList,
    category,
    floor,
  } = useSelector(
    (state) => ({
      loading: state.pois.loading,
      userData: state.auth.userData,
      categoriesList: state.categories.list,
      floorList: state.floor.list,
      category: state.categories.category,
      floor: state.floor.floor,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  if (poi) {
    log(poi, '<<<< POI');
  }

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(poisCleanUp());
  }, [dispatch]);

  useEffect(() => {
    // fetch floor and poi categories
    dispatch(fetchFloor({ siteId }));
    dispatch(fetchCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setPoi);

  const onFileChangedHandler = (file) => {
    setPoi((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const handleDeletePreviewImage = () => {
    setPoi((prevState) => ({
      ...prevState,
      file: null,
      logoUrl: null,
      logo: null,
    }));
  };

  const onSubmitHandler = (event) => {
    console.log(poi);
    event.preventDefault();
    if (
      (!regexInput(poi.name) && poi.name) ||
      (!regexInput(poi.phone) && poi.phone) ||
      (!regexInput(poi.priority) && poi.priority) ||
      // (!regexUrl(poi.url) && poi.url) ||
      (!regexInput(poi.location) && poi.location) ||
      (!regexDescription(poi.description) && poi.description) ||
      (!regexInput(poi.floor) && poi.floor)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(poi.name) &&
        poi.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.phone = !regexInput(poi.phone) &&
        poi.phone && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.priority = !regexInput(poi.priority) &&
        poi.priority && [
          { msg: 'This field cannot contain any special characters' },
        ];
      // newErrors.errors.url = !regexUrl(poi.url) &&
      //   poi.url && [{ msg: 'This field cannot contain any special characters' }];
      newErrors.errors.location = !regexInput(poi.location) &&
        poi.location && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.opentime = !regexInput(poi.opentime) &&
        poi.opentime && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.phone = !regexInput(poi.phone) &&
        poi.phone && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.description = !regexDescription(poi.description) &&
        poi.description && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.floor = !regexInput(poi.floor) &&
        poi.floor && [
          { msg: 'This field cannot contain any special characters' },
        ];

      setErrors(newErrors);
    } else {
      console.log(poi, 'poi');
      dispatch(
        action({
          uid,
          ...poi,
          siteId,
          id,
        })
      );
    }
  };

  const onSelectCategoryHandler = (value) => {
    // const categoryId = value.value;
    // const categoryName = value.label;
    setPoi((prevState) => ({
      ...prevState,
      categoryId: tempSelectedCategory,
    }));
    setPlaylistOpen(false);
  };

  const onSelectFloorHandler = () => {
    // const floorId = value.value;
    // const floorName = value.label;
    setPoi((prevState) => ({
      ...prevState,
      floorId: tempSelectedFloor,
    }));
    setFloorOpen(false);
  };

  // const canSubmit = poi.name && poi.description;

  const imagePreviewUrl = poi.file
    ? poi.file && URL.createObjectURL(poi.file)
    : poi.logo && `${imgUrl}/poi/${poi.logo}`;

  // const goBackMessage = useFormatMessage('PoiForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('PoiForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('PoiForm.pickFile');

  const handleChangeDate = (value) => {
    setPoi({
      ...poi,
      start: value ? value[0] : null,
      end: value ? value[1] : null,
    });
  };

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  const handleNew = () => {
    dispatch(
      setCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalCategory(true)
      )
    );
  };

  const handleNewFloor = () => {
    dispatch(
      setFloor(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalFloor(true)
      )
    );
  };

  const onSubmitCategory = () => {
    const action = category._id ? modifyCategory : createCategory;

    dispatch(
      action({ ...category, siteId, id: category._id, uid }, () =>
        setModalCategory(false)
      )
    );
  };

  const handleSetCategory = (data) => {
    dispatch(setCategory(data));
  };

  const onSubmitFloor = () => {
    const action = floor._id ? modifyFloor : createFloor;

    dispatch(
      action({ uid, ...floor, siteId, id: floor._id }, () =>
        setModalFloor(false)
      )
    );
  };

  const handleSetFloor = (data) => {
    dispatch(setFloor(data));
  };

  const getFloor = (floorId) => {
    const findFloor = floorList.find((floor) => floor._id === floorId);
    return findFloor?.name;
  };

  return (
    <div className="poi-form">
      <ModalCategoryForm
        visible={modalCategory}
        onCancel={() => setModalCategory(false)}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={category._id ? true : false}
      />
      <ModalFloorForm
        visible={modalFloor}
        onCancel={() => {
          setModalFloor(false);
        }}
        onCreate={onSubmitFloor}
        floor={floor}
        setFloor={handleSetFloor}
        isEditing={floor._id ? true : false}
      />
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="POI Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={poi.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Poi Category <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setPlaylistOpen(!playlistOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {poi.categoryId === 'all'
                    ? 'Show All'
                    : getCategory(poi.categoryId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={categoriesList}
                setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                handleSetPlaylist={onSelectCategoryHandler}
                tempSelectedCategory={tempSelectedCategory}
                handleNew={handleNew}
                modalOpen={playlistOpen}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.categoryId[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Floor <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setFloorOpen(!floorOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {poi.floorId === 'all' ? 'Show All' : getFloor(poi.floorId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={floorList}
                setTempSelectedCategory={(id) => setTempSelectedFloor(id)}
                handleSetPlaylist={onSelectFloorHandler}
                tempSelectedCategory={tempSelectedFloor}
                handleNew={handleNewFloor}
                modalOpen={floorOpen}
                title={'Floor'}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.floorId[0].msg}
              </span>
            )}
          </div>

          {/* <div className="form">
            <div className="form-split">
              <div style={{ width: '100%' }}>
                <label className="form-label">Open Time</label>
                <div className="form-group">
                  <InputMask
                    mask="99:99-99:99"
                    placeholder="00:00-00:00"
                    type="text"
                    name="opentime"
                    value={poi.opentime}
                    onChange={onChangeHandler}
                    className={`ant-input ${
                      errors && errors.errors && errors.errors.opentime
                        ? 'has-error'
                        : ''
                    }`}
                  />
                </div>
                {errors && errors.errors && errors.errors.opentime && (
                  <span className="error-message">
                    {errors.errors.opentime[0].msg}
                  </span>
                )}
              </div>
              <div>
                <label className="form-label">Phone Number</label>
                <Input
                  placeholder="POI Phone Number"
                  className={`${
                    errors && errors.errors && errors.errors.phone
                      ? 'has-error'
                      : ''
                  }`}
                  name="phone"
                  value={poi.phone}
                  onChange={onChangeHandler}
                  type="number"
                />
                {errors && errors.errors && errors.errors.phone && (
                  <span className="error-message">
                    {errors.errors.phone[0].msg}
                  </span>
                )}
              </div>
            </div>
          </div> */}

          <div className="form">
            <label className="form-label">Location</label>
            <Input
              placeholder="POI Location"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="location"
              value={poi.location}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.location[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Operating Hours</label>
            <Input
              placeholder="POI Operating Hours"
              className={`${
                errors && errors.errors && errors.errors.opentime
                  ? 'has-error'
                  : ''
              }`}
              name="opentime"
              value={poi.opentime}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.opentime && (
              <span className="error-message">
                {errors.errors.opentime[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Scheduling:</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY"
              // showTime
              onChange={handleChangeDate}
              value={
                poi.start && poi.end
                  ? [moment(poi.start), moment(poi.end)]
                  : undefined
              }
            />
            {/* <Select
              onChange={(value) => handleChangeType(value)}
              value={type}
              defaultValue="all"
            >
              <Select.Option value="all">All Day</Select.Option>
              <Select.Option value="day">Day</Select.Option>
              <Select.Option value="date">Date</Select.Option>
            </Select> */}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Logo</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="POI Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="description"
              value={poi.description}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Phone</label>
            <Input
              placeholder="POI Phone"
              className={`${
                errors && errors.errors && errors.errors.phone
                  ? 'has-error'
                  : ''
              }`}
              name="phone"
              value={poi.phone}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.phone && (
              <span className="error-message">
                {errors.errors.phone[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">URL</label>
            <Input
              placeholder="POI URL"
              className={`${
                errors && errors.errors && errors.errors.url ? 'has-error' : ''
              }`}
              name="url"
              value={poi.url}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.url && (
              <span className="error-message">{errors.errors.url[0].msg}</span>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

PoiForm.propTypes = {
  poiData: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    opentime: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    siteId: PropTypes.string,
    tag: PropTypes.string,
    location: PropTypes.string,
    floor: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default PoiForm;

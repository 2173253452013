import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';
import { calculateData } from './rawAnalytic';
// import { calculateData } from './rawAnalytic';

export const ANALYTICS_FETCH_DATA_INIT = createAction(
  'ANALYTICS_FETCH_DATA_INIT'
);
export const ANALYTICS_FETCH_DATA_SUCCESS = createAction(
  'ANALYTICS_FETCH_DATA_SUCCESS'
);

export const ANALYTICS_FETCH_PROFILE_SUCCESS = createAction(
  'ANALYTICS_FETCH_PROFILE_SUCCESS'
);
export const ANALYTICS_FETCH_DATA_FAIL = createAction(
  'ANALYTICS_FETCH_DATA_FAIL'
);

const cancelToken = axios.CancelToken.source();
export const fetchAnalytics = ({ start, end, type, day, location }) => {
  return async (dispatch, getState) => {
    dispatch(ANALYTICS_FETCH_DATA_INIT());
    let analytic;
    console.log(day, type, 'day loading');
    const data = {
      start,
      end,
      type,
      day,
    };
    if (location) {
      data.location = location;
    }
    try {
      analytic = await axios.post(
        `${url}/facial/analytics`,
        data,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: cancelToken.token,
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_DATA_FAIL({ error }));
    }

    const dataAnalytic = analytic.data.data;

    console.log(dataAnalytic, 'data analytic');
    // if (type === 'month') {
    dispatch(calculateData({ start, end, location }));
    //   return dispatch(
    //     ANALYTICS_FETCH_DATA_SUCCESS({
    //       data: dataAnalytic,
    //       location: analytic.data.location,
    //     })
    //   );
    //   // return dispatch(calculateData({ data: analytic.data.rawData }));
    // }
    return dispatch(
      ANALYTICS_FETCH_DATA_SUCCESS({
        data: dataAnalytic,
        location: analytic.data.location,
      })
    );

    // return dispatch(
    //   ANALYTICS_FETCH_DATA_SUCCESS({
    //     data: dataAnalytic,
    //     location: analytic.data.location,
    //   })
    // );
  };
};

export const fetchProfileAnalytics = ({ start, end, type, day, location }) => {
  return async (dispatch, getState) => {
    dispatch(ANALYTICS_FETCH_DATA_INIT());
    let analytic;
    try {
      const data = {
        start,
        end,
        type,
        day,
      };
      if (location) data.location = location;
      analytic = await axios.post(`${url}/recognition/analytics`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        cancelToken: cancelToken.token,
      });
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_DATA_FAIL({ error }));
    }

    console.log(analytic, 'analytic result');
    return dispatch(
      ANALYTICS_FETCH_PROFILE_SUCCESS({
        data: analytic.data.data,
        location: analytic.data.location,
      })
    );
  };
};

export const cancelAction = () => {
  return async (dispatch, getState) => {
    try {
      cancelToken.cancel();
    } catch (error) {}

    console.log('iscanceled');
    return dispatch(
      ANALYTICS_FETCH_PROFILE_SUCCESS({
        data: {},
        location: [],
      })
    );
  };
};
export const loadingAnalytic = () => {
  return async (dispatch, getState) => {
    console.log('loading analytic');
    return dispatch(ANALYTICS_FETCH_DATA_INIT());
  };
};
